import axios from "axios";

const token = localStorage.getItem("token");

const configuracion = {
  headers: {
    Authorization: `Bearer ` + token,
  },
};

//AWS
// const api = axios.create({
//   baseURL: "http://localhost:3001/api/",
// });
// //const prospeccionApi = axios.create({
// //  baseURL: "http://localhost:4001/api",
// //});

export const getPassengerVehiculs = async (_id) => {
  console.log(_id);
  const { data } = await axios.get(
    "vehiculoPersonal/list_passenger_vehicles/" + _id,
    configuracion
  );
  return data;
};

export const updatePassengerAccess = async (datos) => {
  const { data } = await axios.put(
    "vehiculoPersonal/autorizar_acceso/",
    datos,
    configuracion
  );
  return data;
};
export const updateVehicleAccess = async (datos) => {
  const { data } = await axios.put(
    "vehiculoPersonal/autorizar_acceso_vehiculo/",
    datos,
    configuracion
  );
  return data;
};

export const guardarCambiosTarifas = async (datos) => {
  const { data } = await axios.post(
    "solicitudUnica/guardar_cambios_tarifas/",
    datos,
    configuracion
  );
  return data;
};

export const obtenerTarifa = async () => {
  const { data } = await axios.get(
    "solicitudUnica/obtener_tarifas/",
    configuracion
  );
  return data;
};

export const reprogramarSolicitud = async (datos) => {
  const { data } = await axios.put(
    "solicitudUnica/reprogramar_solicitud/",
    datos,
    configuracion
  );
  return data;
};
