<template>
  <v-layout justify-center>
    <v-flex xs12 sm8 md6 lg4 x14>
      <v-flex xs12
        ><br /><br />
        <picture>
          <source
            srcset="https://i.ibb.co/c1t6rr0/sam-login.png"
            media="screen and (max-width:360px)"
          />
          <v-img src="https://i.ibb.co/c1t6rr0/sam-login.png"> </v-img>
        </picture>

        <br />
      </v-flex>

      <v-container class="justify-center align-center">
        <v-card class="elevation-10" color="#f5f5f5" outlined rounded="lg">
          <v-card-title class="text-h5 text-center font-weight-bold primary--text">
            Iniciar sesión
          </v-card-title>

          <v-form @submit.prevent="ingresar">
            <v-card-text>
              <v-text-field
                v-model="email"
                type="email"
                autofocus
                color="primary"
                label="Correo"
                outlined
                dense
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                type="password"
                color="primary"
                label="Contraseña"
                outlined
                dense
                required
              ></v-text-field>
            </v-card-text>

            <v-card-actions class="px-4">
              <v-btn
                type="submit"
                block
                :loading="loading"
                color="primary"
                class="white--text font-weight-bold"
              >
                Ingresar
              </v-btn>
            </v-card-actions>

            <v-card-text class="text-center pt-3">
              <v-btn text small color="secondary">¿Olvidaste tu contraseña?</v-btn>
            </v-card-text>
          </v-form>

          <v-divider></v-divider>

          <v-card-actions class="text-center pt-3">
            <v-btn text color="primary">Solicitar una cuenta</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      dialog: false,
      errorM: "",
      snackbar: false,
      empresa: "",
      nombre: "",
      direccion: "",
      rol: "",
      cargo: "",
      telefono: "",
      estado: "",
    };
  },
  methods: {
    ingresar() {
      this.loading = true;

      axios
        .post("usuario/login", { email: this.email, password: this.password })
        .then((respuesta) => {
          return respuesta.data;
        })
        .then((data) => {
          this.$store.dispatch("guardarToken", data.tokenReturn);
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.errorM = null;
          if (error.response.status == 404) {
            this.$notify({
              title: "Error!",
              text: "El usuario no existe, favor de verificar sus datos",
              type: "error",
            });
          } else {
            this.errorM = "Ocurrio un error con el servidor";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    guardar() {
      let me = this;
      //Nuevo
      axios
        .post("usuario/addPsp", {
          nombre: this.nombre,
          rol: "PSP",
          estado: 0,
          empresa: this.empresa,
          email: this.email,
          direccion: this.direccion,
          telefono: this.telefono,
          password: this.password,
          cargo: this.cargo,
        })
        .then(function (response) {
          swal(
            "Datos agregados con exito",
            "Para que tu solicitud sea aprobada favor de enviar por correo los siguientes archivos:",
            "success"
          );
          me.close();
          me.limpiar();
        })
        .catch(function (error) {
          me.close();
          me.limpiar();
          swal("Error", "Verique los datos ingreasados" + error, "error");
        });
    },
    limpiar() {
      (this.nombre = ""),
        (this.email = ""),
        (this.empresa = ""),
        (this.cargo = ""),
        (this.telefono = ""),
        (this.direccion = ""),
        (this.contraseña = "");
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
