import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import DatetimePicker from "vuetify-datetime-picker";
import VueBarcode from "vue-barcode";
import Vuelidate from "vuelidate";
import VuePapaParse from "vue-papa-parse";
import VueCountdownTimer from "vuejs-countdown-timer";
import VuetifyMask from "vuetify-mask";
import VueHtml2pdf from "vue-html2pdf";
import VueVuelidateJsonSchema from "vue-vuelidate-jsonschema";
import Toasted from "vue-toasted";
import VCalendar from "v-calendar";
import "./registerServiceWorker";
import Notifications from "vue-notification";

Vue.use(VCalendar, {
  componentPrefix: "v-calendar", // Use <vc-calendar /> instead of <v-calendar />              // ...other defaults
});
//
Vue.use(Toasted);
Vue.use(VuetifyMask);
Vue.use(DatetimePicker);
Vue.use(VueVuelidateJsonSchema);
Vue.use(VueHtml2pdf);
Vue.config.productionTip = false;
Vue.component("barcode", VueBarcode);
Vue.use(Notifications);

//Vue.use(VueQrcodeReader);

//axios.defaults.baseURL = "http://localhost:3001/api/";
axios.defaults.baseURL = "https://sambackend-production.up.railway.app/api/";

const moment = require("moment");
require("moment/locale/es");
Vue.use(VuePapaParse);
Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(Vuelidate);
Vue.use(VueCountdownTimer);

new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
