<template>
  <div>
    <div class="pb-10 d-flex align-center justify-space-between">
      <div>
        <h2>Bitacora de agenciamiento</h2>
        <span style="font-size: 13px; color: gray">
          Registra los accesos y usos de infraestructura de las embarcaciones
        </span>
      </div>

      <v-btn color="black" dark small>Nuevo registro</v-btn>
    </div>
    <v-layout>
      <v-flex>
        <v-row>
          <v-col cols="12">
            <div class="input-filter">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                    <v-icon>filter_list</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item dense @click="showFilterFolio = true">
                    <v-list-item-title>
                      <span class="texto">Folio</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense @click="showFilterFecha = true">
                    <v-list-item-title>
                      <span class="texto">Fecha</span></v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <div class="text-center"></div>
              <div v-if="arr.length == 0" class="filter-label">
                <span>Ningun filtro seleccionado...</span>
              </div>
              <div v-else class="filter-chips">
                <v-chip
                  class="ml-2"
                  v-for="(item, index) in arr"
                  :key="index"
                  close
                  close-icon="mdi-close-circle"
                  @click:close="eliminarFiltro(index)"
                  color="primary"
                  small
                >
                  {{ item.label }}
                </v-chip>
              </div>

              <v-dialog
                v-model="showFilterFecha"
                transition="dialog-bottom-transition"
                max-width="350"
              >
                <v-card>
                  <v-toolbar color="primary" dark>Busqueda por Fecha</v-toolbar>
                  <v-card-text class="mt-10">
                    <v-text-field
                      type="date"
                      outlined
                      dense
                      label="Ingrese la fecha que decea filtrar"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="filtroLabelPush('fecha')">Aplicar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col cols="12">
            <material-card
              icon="content_paste"
              title="Registros capturados "
              class="px-5 py-8"
            >
              <v-data-table
                :headers="headers"
                :items="[]"
                dense
                class="custom-table-header"
                item-value="id"
                hide-default-footer
              >
                <template v-slot:item.opciones="{ item }">
                  <td class="text-right">
                    <v-menu bottom left rounded="lg">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>more_horiz</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item link>
                          <v-list-item-title>Detalles</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </template>
              </v-data-table>
            </material-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import LoadingOverlay from "../components/Loading.vue";
import MaterialCard from "../components/MaterialCard.vue";
export default {
  components: {
    LoadingOverlay,
    MaterialCard,
  },
  data() {
    return {
      showFilterFolio: false,
      showFilterFecha: false,
      headers: [
        { text: "Folio", value: "folio_solicitud" },
        { text: "Embarcación", value: "embarcacion.nombre" },
        { text: "Agencia", value: "agencia" },
        { text: "Atraque", value: "atraque" },
        { text: "Desatraque", value: "muelle" },
        { text: "Amarre/Desamarre", value: "estado" },
        { text: "Movimientos internos", value: "estado" },
        { text: "Embarque (Ton)", value: "estado" },
        { text: "Desembarque (Ton)", value: "estado" },
        { text: "Opciones", value: "opciones", align: "right" },
      ],
      arr: [],
    };
  },
  methods: {
    eliminarFiltro(index) {
      this.arr.splice(index, 1);
    },

    filtroLabelPush(tipo) {
      if (tipo == "folio") {
        let existe = this.arr.findIndex((e) => e.field == "folio");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "folio_falla",
          value: this.folio_filter,
          label: "Folio : " + this.folio_filter,
          collection: "rf",
        });
      } else if (tipo == "fecha") {
        const fecha = new Date(this.fecha_filter);
        let existe = this.arr.findIndex((e) => e.field == "fecha");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "fechaFalla",
          value: fecha,
          label: "Fecha : " + this.fecha_filter,
          collection: "rf",
        });
      }
      this.showFilterFolio = false;
      this.showFilterFecha = false;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

.texto {
  font-family: "Raleway", sans-serif;
}

.v-data-table >>> .v-data-table-header {
  background-color: rgb(23, 23, 87) !important;
}

.input-filter {
  background-color: rgb(241, 241, 241);
  border-radius: 20px;
  padding: -5px;
  width: 100%;
  height: 40px;
}

.input-filter_seguimiento {
  background-color: rgb(226, 226, 226);
  border-radius: 20px;
  padding: -45px;
  width: 100%;
  height: 25px;
}

.input-filter_title {
  background-color: rgb(29, 29, 29);
  border-radius: 20px;
  padding: -45px;
  color: white;
  width: 100%;
  text-align: center;
  height: 25px;
}

.button-fab {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 20px;
  width: 60px;
}

.filter-label {
  color: #b1adad;
  margin-top: -35px;
  margin-left: 40px;
  font-size: 13px;
  font-weight: normal;
}

.filter-chips {
  color: #b1adad;
  padding: -10px;
  margin-top: -41px;
  margin-left: 40px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
</style>
